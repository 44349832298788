import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import withTheme from '../theme';
import { Layout } from '../components1/layout';
import LinedTitle from '../components1/LinedTitle';
import {Title, Text} from '../components1/Texts';
import Section from '../components1/Section';

const LinedTitleResponsive = styled(LinedTitle)`
    padding-top: 40px;
    @media only screen and (max-width: 599px) {
        display: none !important;
    }
`;

const TitleResponsive = styled(Title)`
    padding-top: 15px;
    font-size: 1.4rem;
    /* margin-left: 40px; */
    /* padding-bottom: 10px; */
    /* white-space: nowrap; */

    @media only screen and (min-width: 599px) {
        display: none !important;
    }
`;

const IndexPage = withTheme(({ theme, data }) => (
    <Layout pageName="cgv">
        {
            ({ openConnectForm }) => (
                <>
                    <LinedTitleResponsive
                        borderColor={theme.color.jauneFort}
                        borderWidth="1.5em"
                        style={{
                            paddingLeft: 0,
                            // marginTop: 80
                        }}
                    >
                        <Title>Conditions Générales de Vente</Title>
                    </LinedTitleResponsive>
                    <TitleResponsive
                        style={{
                            textAlign: 'center'
                        }}
                    >
                            Conditions Générales de Vente
                    </TitleResponsive>
                    <Section
                        style={{
                            paddingTop: 30,
                            paddingBottom: 30
                        }}
                    >
                        <Text
                            style={{
                                padding: '10px 30px',
                                textAlign: 'justify',
                            }}
                            css="white-space: pre-line"
                        >
                            {
                                `CONDITIONS GENERALES DE VENTE - CONSOMMATEURS - INTERNET (PRODUITS)

                                A compter du 15/05/2019

                                ARTICLE 1 - Champ d'application

                                Les présentes Conditions Générales de Vente s'appliquent, sans restriction ni réserve, à l'ensemble des ventes conclues par CABADICI (« le Vendeur »), auprès de consommateurs et d'acheteurs non professionnels (« Les Clients ou le Client »), désirant acquérir les produits proposés à la vente par le Vendeur (« Les Produits ») sur le site Internet www.cabadici.fr.

                                La société CABADICI est une SARL au capital de 2.000€ dont le siège social est situé 74 rue des lucques 34570 VAILHAUQUES et immatriculée au RCS de Montpellier sous le numéro 844 638 205.

                                Ces conditions générales de vente précisent notamment les conditions de commande, de paiement, de livraison et d’abonnement des Produits commandés par les Clients.

                                Les Produits proposés à la vente sur le site Internet www.cabadici.fr sont les suivants :

                                - Paniers d'ingrédients accompagnés de recettes

                                Les caractéristiques principales des Produits et notamment les spécifications, illustrations et indications de dimensions ou de capacité des Produits, sont présentées sur le site internet www.cabadici.fr.

                                Le Client est tenu d'en prendre connaissance avant toute passation de commande.

                                Le choix et l'achat d'un Produit est de la seule responsabilité du Client.

                                Les photographies et graphismes présentés sur le site internet www.cabadici.fr, ainsi que les éventuels indications de poids ou de provenance, ne sont pas contractuels et ne sauraient engager la responsabilité du Vendeur.

                                Le Client est tenu de se reporter au descriptif de chaque Produit afin d'en connaître les propriétés et les particularités essentielles.

                                Les offres de Produits s'entendent dans la limite des stocks disponibles, tels que précisés lors de la passation de la commande.

                                D’autres produits sont proposés dans la partie Epicerie du site internet mais ils ne sauraient être considérés comme produits principaux car ne pourront être acquis qu’en complément d’un panier d’ingrédients accompagnés de recettes.

                                Aucune vente depuis l’Epicerie ne pourra être conclue seule, sans achat de Produit principal.

                                Les coordonnées du Vendeur sont les suivantes : 74 rue des lucques 34570 VAILHAUQUES

                                Les présentes Conditions générales de vente s'appliquent à l'exclusion de toutes autres conditions, et notamment celles applicables pour les ventes en magasin ou au moyen d'autres circuits de distribution et de commercialisation.

                                Ces Conditions Générales de Vente sont accessibles à tout moment sur le site Internet www.cabadici.fr et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire. En cas d’inaccessibilité, le client peut transmettre une demande de transmission des présentes conditions générales de vente sur l’email suivant : contact@cabadici.fr.

                                Le Client déclare avoir pris connaissance des présentes Conditions Générales de Ventes et les avoir acceptées en cochant la case prévue à cet effet avant la mise en œuvre de la procédure de commande en ligne ainsi que des conditions générales d'utilisation du site internet www.cabadici.fr.

                                Ces Conditions Générales de Vente pouvant faire l'objet de modifications ultérieures, la version applicable à l'achat du Client est celle en vigueur sur le site internet à la date de passation de la commande.

                                Sauf preuve contraire, les données enregistrées dans le système informatique du Vendeur constituent la preuve de l'ensemble des transactions conclues avec le Client.

                                Conformément à la loi Informatique et Libertés du 6 janvier 1978, renforcée et complétée par le RGPD (règlement général sur la protection des données) entré en vigueur le 25 mai 2018, le Client dispose, à tout moment, d'un droit d'accès, de rectification, d'opposition, d'effacement et de portabilité de l'ensemble de ses données personnelles en écrivant, par courrier et en justifiant de son identité, à CABADICI SARL 74 rue des lucques 34570 VAILHAUQUES

                                La validation de la commande par le Client vaut acceptation sans restriction ni réserve des présentes Conditions Générales de Vente.

                                Le Client reconnaît avoir la capacité requise pour contracter et acquérir les Produits proposés sur le site internet www.cabadici.fr.

                                Les Produits présentés sur le site Internet www.cabadici.fr sont proposés à la vente pour des livraisons en France métropolitaine uniquement. La Corse, les DOM-TOM, et tout autre territoire sont donc exclus. L’offre présentée est disponible en langue française uniquement.

                                Les modifications de ces Conditions Générales de Vente sont opposables aux utilisateurs du site Internet www.cabadici.fr à compter de leur mise en ligne et ne peuvent s'appliquer aux transactions conclues antérieurement.

                                Le site internet www.cabadici.fr est à la disposition des Clients 24h sur 24 et 7 jours sur 7.

                                En cas d’indisponibilité momentanée du site internet, qu’elle soit voulue ou non par le Vendeur (maintenance, cas de force majeure…), le Vendeur ne pourrait être tenu responsable d’un quelconque préjudice.

                                ARTICLE 2 - Commandes

                                Il appartient au Client de sélectionner sur le site internet www.cabadici.fr les Produits qu'il désire commander, selon les modalités suivantes :

                                Le Client doit dans un premier temps créer un compte en renseignant son email et en déterminant son mot de passe. Le client est tenu de conserver ses informations confidentielles et ne pas les communiquer à des tiers. Le vendeur ne pourrait entre tenu pour responsable de leur utilisation frauduleuse.
                                Il sélectionne ensuite sa formule (abonnement ou commande ponctuelle), le nombre de plats désiré, puis la semaine pendant laquelle il souhaite recevoir sa livraison (ou première livraison dans le cas d'un abonnement).
                                Il choisit ensuite son mode de livraison, son créneau de livraison et peut sélectionner des produits dans l'espace Epicerie, proposé en fin de commande.
                                Il renseigne ensuite ses coordonnées et confirme qu'il accepte les présentes conditions générales de vente.
                                Il est ensuite redirigé sur le site de notre prestataire d'encaissement sécurisé Stripe afin de renseigner ses données de carte bancaire et valider son paiement.
                                Il reçoit ensuite un email de confirmation de sa commande et de son règlement.

                                Les informations contractuelles sont présentées en langue française et font l'objet d'une confirmation au plus tard au moment de la validation de la commande par le Client.

                                La création par le Client d’un espace personnel sur le site internet www.cabadici.fr ne lui procure aucun droit particulier, ni aucune obligation, tout comme pour le Vendeur en retour.

                                Les offres de Produits sont valables tant qu'elles sont visibles sur le site internet www .cabadici.fr, dans la limite des stocks disponibles.

                                La vente ne sera considérée comme définitive qu'après l'envoi au Client de la confirmation de l'acceptation de la commande par le Vendeur par courrier électronique et après encaissement par celui-ci de l'intégralité du prix.

                                Pour les commandes passées exclusivement sur internet, l'enregistrement d'une commande sur le site du Prestataire est réalisé lorsque le Client accepte les présentes Conditions Générales de Vente en cochant la case prévue à cet effet et valide sa commande. Le Client a la possibilité de vérifier le détail de sa commande, son prix total et de corriger d'éventuelles erreurs avant de confirmer son acceptation (article 1127-2 du Code Civil). Cette validation implique l'acceptation de l'intégralité des présentes Conditions Générales de Vente et constituent une preuve du contrat de vente.

                                Il appartient donc au Client de vérifier l'exactitude de la commande et de signaler immédiatement toute erreur.

                                Toute commande passée sur le site internet www.cabadici.fr constitue la formation d'un contrat conclu à distance entre le Client et le Vendeur.

                                Le Vendeur se réserve le droit d'annuler ou de refuser toute commande d'un Client avec lequel il existerait un litige relatif au paiement d'une commande antérieure.

                                Le Vendeur n'a pas vocation à vendre les Produits sur le site internet www.cabadici.fr à des professionnels, mais uniquement à des consommateurs ou à des non professionnels, pour leurs besoins personnels.

                                Le Vendeur se réserve donc le droit de refuser les commandes d'un même Produit en quantités importantes.

                                Aucune commande d'un montant inférieur au montant d'un panier de 2 recettes ne pourra être acceptée.

                                Les éventuelles modifications de la commande par le Client ne pourront être prises en compte par le Vendeur que dans la limite de ses possibilités et à condition d'être notifiées dans son espace client au Vendeur avant le dimanche soir minuit de la semaine précédant la livraison, soit 8 jours au moins avant la date prévue pour l'expédition de la commande.

                                Dans l'hypothèse où ces modifications ne pourraient être acceptées par le Vendeur, les sommes versées par le Client lui seront restituées dans un délai maximum de 10 jours à compter de la notification de l'impossibilité d'accepter les modifications demandées par le Client (à moins que celui-ci ne préfère bénéficier d'un avoir).

                                ARTICLE 3 – Produits

                                Les Produits étant constitués d’ingrédients diverses, le Client est informé que chaque ingrédient est issu d’un fournisseur différent. Les caractéristiques de ces ingrédients sont précisées par une étiquette et les informations qui y figurent sont transmises par chacun des fournisseurs. Le Vendeur ne saurait être tenu pour responsable si ces informations données par ces fournisseurs sont erronées.

                                Les quantités d’ingrédients précisées sur les fiches recettes pourront être légèrement différentes des quantités réellement livrées sans que cela n’entraîne aucun préjudice pour la bonne exécution de ladite recette et donc aucun préjudice pour le Client.

                                Ainsi, le Client ne pourra prétendre à aucun dédommagement de quelle nature que ce soit si les quantités ne sont pas les exactement les mêmes, les différences étant minimes et sans incidence sur la globalité de sa commande. Plus précisément, le poids des ingrédients à la coupe ou des paniers de fruits disponibles en complément dans l’Epicerie pourra être différent dans une fourchette raisonnable sans que cela n’entraîne aucune modification ou réclamation sur le tarif dudit produit ou de la commande globale.

                                Certains ingrédients pourront également parfois être remplacés par d’autres ingrédients similaires, en cas d’indisponibilité de la part des fournisseurs du Vendeur. Cela pourra être le cas par exemple pour des herbes aromatiques, légumes, fruits ou poissons qui sont dépendants d’une production saisonnière et d’un climat précis. Le Vendeur fera tout ce qui est en son possible pour trouver une alternative équivalente afin de ne pas altérer le goût de la recette finale tout en conservant un approvisionnement en adéquation avec ses valeurs.

                                Dans la mesure du possible, et si le remplacement relève d’une importance capitale pour le Client (en raison d’allergies possibles par exemple), le Vendeur préviendra à l’avance le Client de ce remplacement, par email. Si le remplacement est mineur, le Client en sera informé au moment de la réception de sa commande, par une petite note à l’intérieur de son panier.

                                En aucun cas le Client n’aura la possibilité de demander une remboursement partiel ou total de sa commande dû au fait d’un remplacement de produit par un autre similaire.

                                Dans le cas d’une indisponibilité de produit vendu en complément dans l’Epicerie, le Vendeur proposera un remplacement, un avoir ou un remboursement, par téléphone et par email au Client.

                                ARTICLE 4 - Tarifs

                                Les Produits sont fournis aux tarifs en vigueur figurant sur le site Internet www.cabadici.fr, lors de l'enregistrement de la commande par le Vendeur. Les prix sont exprimés en Euros, toutes taxes comprises, et plus précisément après application de la TVA française au taux en vigueur au moment de la commande pour le type de produit concerné.

                                Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Vendeur sur le site internet www.cabadici.fr.

                                Ces tarifs sont révisables à tout moment par le Vendeur et sont applicables uniquement aux commandes ultérieures. Ces éventuelles modifications de prix seront signalées au client au moment de sa commande suivante. Les tarifs indiqués ne comprennent pas les frais de traitement, d'expédition, de transport et de livraison, qui sont facturés en supplément, dans les conditions indiquées sur le site internet www.cabadici.fr et calculés préalablement à la passation de la commande.

                                Le paiement demandé au Client correspond au montant total de l'achat, y compris ces frais.

                                Une facture est disponible sur demande du Client au Vendeur par email à contact@cabadici.fr.

                                Si une remise de prix est consentie par le Vendeur, qu’elle soit totale ou partielle, elle ne saurait être valable que pour un temps donné (pour une livraison, pour une semaine…). Dès la période de temps terminée, les tarifs en vigueur seront appliqués sans préavis quelconque.

                                En cas de remise de prix totale, et même s’il ne choisit pas de s’abonner tout de suite, le Client devra tout de même entrer ses coordonnées bancaires et compléter son formulaire d’inscription pour pouvoir notamment profiter des produits proposés dans l’Epicerie.

                                ARTICLE 5 - Conditions de paiement

                                Le prix est payable comptant, en totalité au jour de la passation de la commande par le Client, par voie de paiement sécurisé, uniquement par carte bancaire.

                                Le retard de paiement entraînera l'exigibilité immédiate de l'intégralité des sommes dues par le Client, sans préjudice de toute autre action que le Vendeur serait en droit d'intenter, à ce titre, à l'encontre du Client.

                                Les paiements effectués par le Client ne seront considérés comme définitifs qu'après encaissement effectif des sommes dues, par le Vendeur.

                                En outre, le Vendeur se réserve le droit, en cas de non-respect des conditions de paiement figurant ci-dessus, de suspendre ou d'annuler la livraison des commandes en cours effectuées par le Client.

                                En parallèle, le Vendeur s’engage à mettre en œuvre toutes les mesures mises à sa disposition pour garantir un paiement sécurisé. Le prestataire du Vendeur utilise une solution de paiement sécurisée dotée d’un protocole TLS (Transport Layer Security) afin d’offrir un maximum de sécurité au Client grâce à un cryptage de ses données évitant toute récupération par un tiers au cours du paiement.

                                Cela dit, le Vendeur ne pourra en aucun cas garantir que la transmission des données du Client soit protégée à 100%. Le Client assume l’entièreté des risques induits par tout paiement sur Internet.

                                En cas d’abonnement, les coordonnées bancaires du Client seront alors enregistrées par le prestataire en données cryptées, et ce afin de ne pas contraindre le Client à entrer ses données chaque semaine. Ainsi, chaque semaine, le samedi à 00h, le montant de la commande validée par le Client sera automatiquement débité sur sa carte bancaire.

                                Si le Client reprend son abonnement après avoir suspendu ses livraisons pendant une semaine, sa carte bancaire sera débitée à nouveau automatiquement la semaine suivante.

                                S’il réactive son abonnement après l’avoir résilié, alors la validation de sa commande « de reprise » sera prise en compte le jour de son « retour » et son paiement total sera immédiat.

                                Par ailleurs, si le Client ajoute des produits complémentaires à sa commande avant le dimanche soir 23h59, alors que son règlement a déjà été débité (en cas de première commande ou de réactivation de son abonnement), un second débit sera effectué sur sa carte bancaire le samedi à 00h.

                                Dans le cas où il ajoute des produits complémentaires alors que son abonnement suit son cours normal, alors le montant débité le samedi 00h sera simplement majoré du montant des produits complémentaires sélectionnés.

                                Si un paiement est refusé par le centre de paiement bancaire du Client, la commande ne saurait être confirmée et ne sera pas considérée comme validée par le Vendeur.

                                ARTICLE 6 - Livraisons

                                Les Produits commandés par le Client seront livrés en France métropolitaine uniquement et à l'adresse indiquée par le Client lors de sa commande sur le site Internet www.cabadici.fr. Le Client est responsable de la saisie des coordonnées de livraison et le Vendeur ne pourra être tenu pour responsable d’un défaut de livraison en raison d’une erreur de saisie.

                                La livraison est constituée par le transfert au Client de la possession physique ou du contrôle du Produit.

                                Sauf cas particulier ou indisponibilité d'un ou plusieurs Produits, les Produits commandés seront livrés en une seule fois.

                                Le Vendeur s'engage à faire ses meilleurs efforts pour livrer les produits commandés par le Client dans les délais ci-dessus précisés. Toutefois, ces délais sont communiqués à titre indicatif.

                                Les livraisons sont assurées par le Vendeur lui-même, ou par un transporteur indépendant, à l'adresse mentionnée par le Client lors de la commande et à laquelle le transporteur pourra facilement accéder. Le client peut également décider de se faire livrer dans un commerce partenaire. Pour cela, il devra se munir d’une pièce d’identité.

                                Aucune livraison ne pourra avoir lieu lors de jours fériés, le Vendeur préviendra les Clients au moins 15 jours avant de toutes modification de planning de livraison le cas échéant (report des livraison au jeudi ou au samedi ou annulation des livraisons cette semaine là). Le Client pourra alors choisir de suspendre sa livraison s’il le souhaite.

                                Le Vendeur pourra être amené à suspendre les livraisons pendant une durée déterminée à l’avance et précisée sur le site internet www.cabadici.fr ainsi que par mail. Ce sera notamment le cas les mois d’Août de chaque année ainsi que pendant les fêtes de fin d’année.

                                Les livraisons dites « privées » seront effectuées à l’adresse entrée par le Client, sans distinction qu’il s’agisse de son domicile ou non. Le Vendeur propose des créneaux de livraison (matin, après-midi ou journée totale) et fera son maximum pour respecter le choix des Clients à ce sujet.

                                Toutefois, en cas de retard ou d’impossibilité de la part du Vendeur d’assurer toutes les livraisons si le même créneau a été choisi par un nombre de clients trop important, le Vendeur se réserve la possibilité de proposer au Client une solution qui conviendrait à tous. Ainsi, le Vendeur pourra appeler ou envoyer des SMS au client afin de convenir d’un rendez-vous ou d’un autre créneau.

                                Si la livraison est effectuée par transporteur et non directement par le Vendeur, les modalités de livraison seront précisées au client par le transporteur directement.

                                Le Vendeur s’engage à respecter la chaîne du froid tout le long du transport.

                                Le Client s’engage, lui, à être présent au moment de la livraison à l’adresse prévue afin de récupérer sa commande.

                                Si la livraison ne peut être effectuée en cas d’absence du Client ou si les coordonnées ne sont pas renseignées correctement par le Client, le Vendeur ne pourra être tenu pour responsable. Une re-livraison pourra être prévue et les frais occasionnés seront à la charge du Client. Le Vendeur contactera alors le Client pour prévoir un autre rendez-vous.

                                Si le Client ne répond pas au téléphone ou au SMS dans l’heure, le Vendeur ne pourra être tenu de re-proposer une seconde livraison.

                                De la même façon, si une seconde livraison n’est pas possible (indifféremment de la part du Vendeur ou du Client) dans les délais raisonnables à la bonne conservation des ingrédients, la commande sera perdue pour le Client et aucun remboursement partiel ou total ne pourra être attendu, ni aucun avoir.

                                Quelque soit le mode de livraison choisi, les denrées seront livrées dans un emballage permettant de se conserver dans de bonnes conditions d’hygiène pour une durée de 12 Heures (utilisation de plaques eutectiques et d’un emballage spécifique pour les denrées sensibles).

                                Le Client devra vérifier la qualité et l’exhaustivité des emballages et des produits à réception de sa commande et dispose d’un délai de 24H pour formuler par email (contact@cabadici.fr) toutes réserves ou réclamations pour non-conformité ou vice apparent des Produits livrés (par exemple colis endommagé déjà ouvert ...), avec tous les justificatifs y afférents (photos notamment). Passé ce délai et à défaut d'avoir respecté ces formalités, les Produits seront réputés conformes et exempts de tout vice apparent et aucune réclamation ne pourra être valablement acceptée par le Vendeur.

                                Le Vendeur remboursera ou remplacera dans les plus brefs délais et à ses frais, les Produits livrés dont les défauts de conformité ou les vices apparents ou cachés auront été dûment prouvés par le Client, dans les conditions prévues aux articles L 217-4 et suivants du Code de la consommation.

                                Le Client est également tenu de consommer ses produits dans un délai de 5 jours maximum après sa livraison. Certains produits, comme le poisson, pourront avoir des Dates Limites de Consommation (DLC) plus courtes, qui seront mentionnées sur les emballages. Etant donné que les Produits livrés n’auront jamais été surgelés par le passé, le Client pourra avoir la possibilité de procéder immédiatement après la livraison à la congélation de ses produits s’il ne peut les consommer dans ce délai. En cas de congélation, il devra ensuite veiller à la correcte décongélation de ces produits, notamment concernant les règles d’hygiène qui s’y rapportent. Le Vendeur ne pourra être tenu pour responsable d’une quelconque altération de la qualité des Produits en cas de congélation.

                                Le Client est responsable de la correcte manipulation, conservation et préparation des éléments constitutifs de sa commande à partir de la livraison. Le Vendeur ne pourrait être tenu pour responsable de tout dommages engendrés par un défaut de manipulation ou de conservation, notamment concernant les règles d’hygiène à respecter par le Client, après la livraison de sa commande.

                                ARTICLE 7 - Transfert de propriété - Transfert des risques

                                Quelle que soit la date du transfert de propriété des Produits, le transfert des risques de perte et de détérioration s'y rapportant, ne sera réalisé qu'au moment où le Client prendra physiquement possession des Produits. Les Produits voyagent donc aux risques et périls du Vendeur.

                                ARTICLE 8 - Droit de rétractation

                                Conformément à l’article L221-28 du Code de la consommation, le droit de rétractation ne peut être exercé du fait de la nature des produits vendus, susceptible de se détériorer ou de se périmer rapidement.

                                ARTICLE 9 - Abonnement

                                Au moment de son inscription, le Client a le choix entre une commande ponctuelle et un abonnement.

                                S’il choisir une commande ponctuelle, le tarif annoncé sera alors majoré de 5€ TTC et il en sera informé dès le début de sa commande.

                                S’il choisit l’abonnement, une commande sera automatiquement prévue pour lui chaque semaine, par tacite reconduction.

                                L’abonnement est flexible puisque modifiable chaque semaine et sans engagement de durée, à partir de la deuxième commande. En effet, l’abonnement donnant accès à un tarif préférentiel pour le Client, il s’engage à passer au minimum 2 commandes pour pouvoir en bénéficier.

                                L’abonnement est donc qualifié d’abonnement « sans engagement » mais il est précisé sans équivoque sur le site internet www.cabadici.fr qu’il sera résiliable à partir de 2 commandes passées, sans obligation que ces commandes ne soient ni consécutives ni identiques (nombre de recettes, produits complémentaires…)

                                Le Client devra alors se connecter sur le site chaque semaine avant le dimanche soir 23h59 afin de modifier ses choix s’il le désire. En cas d’inaction avant le dimanche soir 23h59 par le Client, ses choix effectués la semaine précédente seront repris (nombre de recettes à recevoir, compléments éventuels de l’Epicerie, modalités de livraison), la commande sera considérée comme validée par ses soins et le règlement sera débité sur sa carte bancaire le samedi à 00h.

                                Aucun remboursement ne pourra être effectué après validation de la commande, qu’elle soit tacite ou active.

                                Il sera ensuite contacté par email pour l’informer de sa commande à venir pour le vendredi suivant.

                                Le Client a la possibilité, avant toute validation (durant la semaine précédente avant le dimanche soir 23h59), dans son espace personnel :

                                - de modifier le contenu de sa commande (ajouter ou enlever des Produits et produits complémentaires),

                                - de modifier son adresse de livraison ou son choix de livraison (créneau, point partenaire…),

                                - de suspendre la livraison de la semaine à venir (son abonnement reprendra alors normalement la semaine suivante),

                                - ou bien encore de résilier son abonnement pour une durée indéterminée.

                                Il pourra ensuite le réactiver quand il le souhaite, au même endroit.

                                Si le Client suspend ou résilie son abonnement après le dimanche soir 23h59, sa commande sera considérée comme validée, son paiement sera donc automatiquement débité et le Vendeur ne pourra procéder à aucun remboursement.

                                Pour ne pas prendre au dépourvu le Client, le Vendeur lui rappellera par email chaque semaine de se rendre sur son espace personnel pour modifier ou annuler sa prochaine livraison.

                                En cas de litige ou si le Client refuse sa livraison au titre qu’il n’a fait aucune action spécifique pour la recevoir, le Vendeur ne pourra rembourser les sommes encaissées, les présentes Conditions Générales de Vente ayant été acceptées, et les modalités de l’abonnement tacite ayant été explicites et sans aucune mesure dissimulées par le Vendeur.

                                Par ailleurs, il est à noter qu’un seul abonnement par Client pourra être possible. Le Vendeur se réserve donc la possibilité de refuser une commande si elle provient de deux Clients portant le même nom et/ou ayant la même adresse postale. Le Vendeur se réserve également la possibilité de résilier le compte d’un Client sans aucun préavis s’il estime qu’il s’agit d’un compte frauduleux, inexact ou d’un doublon.

                                ARTICLE 10 - Informatiques et Libertés

                                En application de la loi 78-17 du 6 janvier 1978 modifiée par la loi n°2018-493 du 20 juin 2018, il est rappelé que les données nominatives demandées au Client sont nécessaires au traitement de sa commande et à l'établissement des factures, notamment.

                                Ces données peuvent être communiquées aux éventuels partenaires du Vendeur chargés de l'exécution, du traitement, de la gestion et du paiement des commandes.

                                Le traitement des informations communiquées par l'intermédiaire du site internet www.cabadici.fr répond aux exigences légales en matière de protection des données personnelles, le système d'information utilisé assurant une protection optimale de ces données.

                                Le Client dispose, conformément aux réglementations nationales et européennes en vigueur d'un droit d'accès permanent, de modification, de rectification, d'opposition de portabilité et de limitation du traitement s'agissant des informations le concernant.

                                Ce droit peut être exercé dans les conditions et selon les modalités définies sur le site internet www.cabadici.fr.

                                L’utilisation de cookies est soumise à la validation du visiteur et la collecte de ces données est effectuée à des seules fins de statistiques de navigation et pourront également être utilisés pour la diffusion de publicité ciblée.

                                ARTICLE 11 - Propriété intellectuelle

                                Le contenu du site internet www.cabadici.fr est la propriété du Vendeur et de ses partenaires et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.

                                Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.

                                En outre, Le Vendeur reste propriétaire de tous les droits de propriété intellectuelle sur les photographies, présentations, études, dessins, modèles, prototypes, etc, réalisés (même à la demande du Client) en vue de la fourniture des Services au Client. Le Client s'interdit donc toute reproduction ou exploitation desdites études, dessins, modèles et prototypes, etc, sans l'autorisation expresse, écrite et préalable du Vendeur qui peut la conditionner à une contrepartie financière.

                                ARTICLE 12 – Inexécution

                                En cas d’impossibilité d’honorer la commande passée par le Client, du fait d’un empêchement tant lié à la préparation de la commande (défaut d’un fournisseur, indisponibilité d’un produit), qu’à sa livraison (défaut du transporteur ou des moyens matériels mis en place par le Vendeur lui-même), le Vendeur informera le Client dans les délais les plus brefs et lui donnera la possibilité de modifier sa commande ou bien de l’annuler, en contrepartie du remboursement des sommes réglées.

                                Le remboursement sera limité aux seules sommes réglées par le Client au moment de sa commande et ne pourrons être étendus à des dommages indirects.

                                ARTICLE 13 - Force majeure

                                Les Parties ne pourront être tenus pour responsables si la non-exécution ou le retard dans l'exécution de l'une quelconque de leurs obligations, telles que décrites dans les présentes découle d'un cas de force majeure, au sens de l'article 1218 du Code civil.

                                La Partie constatant l'événement devra sans délai informer l'autre partie de son impossibilité à exécuter sa prestation et s'en justifier auprès de celle-ci. La suspension des obligations ne pourra en aucun cas être une cause de responsabilité pour non-exécution de l'obligation en cause, ni induire le versement de dommages et intérêts ou pénalités de retard.

                                L'exécution de l'obligation est suspendue pendant toute la durée de la force majeure si elle est temporaire. Par conséquent, dès la disparition de la cause de la suspension de leurs obligations réciproques, les Parties feront tous leurs efforts pour reprendre le plus rapidement possible l'exécution normale de leurs obligations contractuelles. A cet effet, la Partie empêchée avertira l'autre de la reprise de son obligation par lettre recommandée avec demande d'avis de réception ou tout acte extrajudiciaire. Si l'empêchement est définitif, les présentes seront purement et simplement résolues, sans sommation ni formalité.

                                ARTICLE 14 - Droit applicable - Langue

                                Les présentes Conditions Générales de Vente et les opérations qui en découlent sont régies et soumises au droit français.

                                Les présentes Conditions Générales de Vente sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.

                                ARTICLE 15 - Litiges

                                Tous les litiges auxquels les opérations d'achat et de vente conclues en application des présentes conditions générales de vente pourraient donner lieu, concernant tant leur validité, leur interprétation, leur exécution, leur résiliation, leurs conséquences et leurs suites et qui n'auraient pu être résolues entre le vendeur et le client seront soumis aux tribunaux compétents dans les conditions de droit commun.

                                Le Client est informé qu'il peut en tout état de cause recourir à une médiation conventionnelle, notamment auprès de la Commission de la médiation de la consommation (C. consom. art. L 612-1) ou auprès des instances de médiation sectorielles existantes, et dont les références figurent sur le site Internet www.cabadici.fr ou à tout mode alternatif de règlement des différends (conciliation, par exemple) en cas de contestation.

                                Le client, constatant qu'une violation au règlement général sur la protection des données personnelles aurait été commise, a la possibilité de mandater une association ou un organisme mentionné au IV de l'article 43 ter de la loi informatique et liberté de 1978, afin d'obtenir contre le responsable de traitement ou sous-traitant, réparation devant une juridiction civile ou administrative ou devant la commission nationale de l'informatique et des libertés.

                                ARTICLE 16 - Information précontractuelle - Acceptation du Client

                                Le fait pour une personne physique (ou morale), de commander sur le site Internet www.cabadici.fr emporte adhésion et acceptation pleine et entière des présentes Conditions Générales de Vente et obligation au paiement des Produits commandés, ce qui est expressément reconnu par le Client, qui renonce, notamment, à se prévaloir de tout document contradictoire, qui serait inopposable au Vendeur.

                                ARTICLE 17 – Service Relation Client

                                Pour contacter le Vendeur, le Client peut :

                                - envoyer un email à l’adresse contact@cabadici.fr

                                - appeler le 07 56 87 34 12 entre 9h et 17h du lundi au vendredi

                                - envoyer un SMS ou MMS au 07 56 87 34 12

                                - envoyer un courrier au 74 rue des Lucques 34570 VAILHAUQUES`
                            }
                        </Text>
                    </Section>
                </>
            )
        }
    </Layout>
));

IndexPage.propTypes = {
    theme: PropTypes.shape().isRequired
};

export default IndexPage;
